import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons'

const LayoutButton = ({ onOpen }) => {
  return (
    <IconButton
      colorScheme=''
      icon={<HamburgerIcon color='black'/>}
      // colorScheme='blackAlpha'
      aria-label='Open Sidebar'
      onClick={onOpen}
    />
    // <Button colorScheme='teal' onClick={onOpen}>
    //   Open
    // </Button>
  );
};

export default LayoutButton;
