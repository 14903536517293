// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-container {
    display: flex;
    flex-direction: column;
}

div.row-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.row-element {
    /* padding-left: 2px; */
}

h1 {
    text-align: center;
}

button {
    text-align: center;
    width: auto;
}

.container {
    display:flex;
    flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/admin/List/PhysiciansList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,sBAAsB;AAC1B","sourcesContent":[".list-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\ndiv.row-container {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: left;\r\n}\r\n\r\n.row-element {\r\n    /* padding-left: 2px; */\r\n}\r\n\r\nh1 {\r\n    text-align: center;\r\n}\r\n\r\nbutton {\r\n    text-align: center;\r\n    width: auto;\r\n}\r\n\r\n.container {\r\n    display:flex;\r\n    flex-direction: column;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
