import React from 'react'
import { useDisclosure } from '@chakra-ui/react'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Text
} from '@chakra-ui/react'
import { useState } from 'react';

import LayoutButton from './component/LayoutButton'
import LayoutSidebar from './component/LayoutSidebar'

import './Layout.css';

function Layout({ children }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedCategory, setSelectedCategory] = useState('');

    return (
        <div>
            <LayoutButton onOpen={onOpen} />
            <LayoutSidebar isOpen={isOpen} onClose={onClose} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />

            {/* Autres éléments du layout */}
            <div className="children">
                {children}    
            </div>
        </div>
    );
}

export default Layout;