// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .modal-container {
    background-color: red;
    overflow-y: scroll;
    height: 300px;
    width: 300px;

}

.input-ttt {

} */`, "",{"version":3,"sources":["webpack://./src/packs/Modal/CreateTreatment.css"],"names":[],"mappings":"AAAA;;;;;;;;;;GAUG","sourcesContent":["/* .modal-container {\r\n    background-color: red;\r\n    overflow-y: scroll;\r\n    height: 300px;\r\n    width: 300px;\r\n\r\n}\r\n\r\n.input-ttt {\r\n\r\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
