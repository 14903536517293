import React, { useState, useEffect } from "react";
import {
    Button,
    Tabs, TabList, TabPanels, Tab, TabPanel
  } from '@chakra-ui/react'


 import CreateTreatment from "./Modal/CreateTreatment";

import './Pack.css';
import TreatmentsList from "./List/TreatmentsList";
import PacksRow from "./List/PacksRow";
import DocumentsRow from "./List/DocumentsRow";
import ElementsRow from "./List/ElementsRow";
import { getAllTreatments } from "../services/application";

function Pack() {
    const [treatments, setTreatments] = useState([]);
    const [updateTrigger, setUpdateTrigger] = useState(false);



    useEffect(() => {
        const fetchTreatments = async () => {
            try {
                const response = await getAllTreatments();
                
                setTreatments(response.data);
            } catch (error) {
                console.error("Une erreur s'est produite lors de la récupération des traitements :", error);
            }
        };

        fetchTreatments();
    }, [updateTrigger]);

    const handleTreatmentUpdate = () => {
        setUpdateTrigger(prevState => !prevState); // Inversion de la valeur pour déclencher le useEffect
    };

    const handleClick = () => {
        console.log("here")
    }

    return(
        <>
            <div className="create-menu">
                <CreateTreatment onTreatmentCreated={handleTreatmentUpdate} />
                <Button>Créer élément</Button>
                <Button>Créer pack</Button>
                <Button>Créer document</Button>
            </div>
            <div>
                <Tabs>
                    <TabList>
                        <Tab>Traitements</Tab>
                        <Tab>Packs</Tab>
                        <Tab>Documents</Tab>
                        <Tab>Elements</Tab>
                    </TabList>

                    <TabPanels>

                        <TabPanel>
                            <TreatmentsList treatments={treatments}/>
                        </TabPanel>

                        <TabPanel>
                            <PacksRow/>
                        </TabPanel>

                        <TabPanel>
                            <DocumentsRow/>
                        </TabPanel>

                        <TabPanel>
                            <ElementsRow/>
                        </TabPanel>

                    </TabPanels>
                </Tabs>
            </div>
    {/* <TableContainer>
        <Table variant='simple' colorScheme='black'>
            <Thead>
            <Tr>
                <Th>Code TTT</Th>
    
            </Tr>
            </Thead>
            <Tbody>
                <Row codeTTT="1234" ></Row>

            </Tbody>

        </Table>
    </TableContainer> */}
    </>
            )
}

export default Pack;
