import React from 'react';

import { useNavigate } from 'react-router-dom';

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
  Text,
  Link
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
 import './LayoutSidebar.css';
import { logOut } from '../../services/users';
import LogOutButton from '../../components/LogOutButton';

const LayoutSidebar = ({ isOpen, onClose, finalFocusRef }) => {

  
  return (
    <Drawer isOpen={isOpen} placement='left' onClose={onClose} finalFocusRef={finalFocusRef} color='blue'>
      <DrawerOverlay/>
      <DrawerContent>
        <LogOutButton/>
        <DrawerCloseButton/>
        {/* <DrawerHeader>Menu</DrawerHeader> */}

        <DrawerBody>
          {/* Wrap the first Text in a Link component */}

          <div className='categorie'>
            <Link as={RouterLink} to="/user/home">
                <Text >
                  Générer un parcours
                </Text>
            </Link>
          </div>
          <div className='categorie'>
            <Link as={RouterLink} to="/add">
                <Text >
                  Ajouter un practicien
                </Text>
            </Link>
          </div>
  
        <div className='categorie'>
          <Link as={RouterLink} to="/user/packs">
            <Text >
              Gestion des packs
            </Text>
          </Link>
        </div>

        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default LayoutSidebar;
