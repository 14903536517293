import './Login.css';
import { Input, Button, useDisclosure, Modal, ModalOverlay, ModalBody,ModalContent, ModalFooter, ModalHeader, ModalCloseButton } from '@chakra-ui/react'
import { useNavigate, Link } from 'react-router-dom';
import { useState, useEffect } from "react";
import { getProfileInfos, logAdmin, logOut, logPhysician, login } from '../services/users';

import logo from '../assets/logo.png';
import digilForest from '../assets/digital_forest.png';
import digilForest2 from '../assets/digital_forest2.png';

import keri from '../assets/keri.jpg';

// async function fetchData(url, options = { method: 'GET' }) {
//   let res = await fetch(url, options);
//   let json = await res.json()
//   return json
// }


function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  // const { isOpen, onOpen, onClose } = useDisclosure()
  const onClose = () => {
    setIsErrorModalOpen(false);
  }

  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    console.log(email)
  }

  const handlePasswordChange = () => {
    setPassword(e.target.value)
  }

  const handleLogin = async () => {

    // var mail = "admin@admin.admin";
    // var password =  "P32^VWd+q?xOYo[FU'R.";

// validated user
// "test@gmail.com"
// "Azertyuiop123$$"
    try {
      const response = await login(email, password);
      console.log(response);
      
      if(response.status === 200) {
        // TO DO: gérere if admin or random user
        var profileInfos = await getProfileInfos();
        var isAdmin = profileInfos.data.isAdmin;
        if(isAdmin === 1) {
          console.log("admin logged")
          navigate("/admin")
        } else {
          navigate("/user/home");
          console.log("")
        }

        // navigate('/user/home');
      }

    } catch(error) {
      setIsErrorModalOpen(true);
      console.log("Identifiants incorrects")

      console.log(error)
    }


    //  TO DO : if is admin /admin
    // if is user /user
    // else => modal log incorrect
  };
  

  // const [message, setMessage] = useState("");

  // useEffect(() => {

  //   // ! You MUST use await to fetch data from the server, hence why it is encapsulated in an async function. Making functions from the upper scope asynchronous may throw
  //   // ! critical errors within react 
  //   async function doThings() {
  //     let json = await fetchData("http://localhost/message")
  //     setMessage(json.message)
  //     console.log(message)
  //   }
  //   doThings()
  // }, [])

  return (
    <>
      <div className='login'> 
        <img src={logo} alt="Logo" />
        {/* <h1>Easy For Doc + logo</h1> */}
        <div class="header">
        <a href="#">Identifiez-vous</a>
        <span>|</span>
        <Link to="/create">Créer un compte</Link>
        </div>

        <Input placeholder="Email" borderColor='black' onChange={(e) => setEmail(e.target.value)}/>
        <Input placeholder="Mot de passe" borderColor='black' onChange={(e) => setPassword(e.target.value)}/>
        <Button colorScheme='blue' onClick={handleLogin}>Connexion</Button>
      </div>

      <div>
        <Modal isOpen={isErrorModalOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Erreur</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <p>Identifiants incorrects</p>
            </ModalBody>

            <ModalFooter>
              {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
                Close
              </Button> */}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>

      <div className="footer">
        <div className="associates">
          <div className="digital_forest">
            <img src={digilForest} alt="digital-forest" />
            <img src={digilForest2} alt="digital-forest2" /> 
          </div>

          <img src={keri} alt="keri" /> 
        </div>


      </div>
    </>

  );
}

export default Login;
