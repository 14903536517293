import axios from 'axios';

import apiUrl from "../config";


export async function login(mail, password) {
    const loginData = {
        "mail": mail,
        "password": password
      }

      try {
        const response = await axios.post(apiUrl + "/sessions/login", loginData, {
            withCredentials: true
        });

        return response;    
      } catch(error) {
        throw new Error(error);
      }
}

// export async function logAdmin() {
//   const loginData = {
//       "mail": "admin@admin.admin",
//       "password": "P32^VWd+q?xOYo[FU'R."
//     }

//     try {
//       const response = await axios.post(apiUrl + "/sessions/login", loginData, {
//           withCredentials: true
//       });

//       return response;    
//     } catch(error) {
//       throw new Error(error);
//     }
// }

export async function logPhysician() {
  const loginData = {
      "mail": "example@gmail.com",
      "password": "abcdeABCDE1234?"
    }

    try {
      const response = await axios.post(apiUrl + "/sessions/login", loginData, {
          withCredentials: true
      });

      return response;    
    } catch(error) {
      throw new Error(error);
    }
}

export async function validUser(adeli) {
  try {
    const response = await axios.put(apiUrl + "/database/admin/physicians", {
      "searchFields": "adeli",
      "updateFields": "isValidated",
      "adeli": adeli,
      "isValidated": "1"
      }, {
        withCredentials: true
      });

      return response;
  } catch(error) {
    throw new Error(error);
  }
}

export async function logOut() {
  try {
    const response = await axios.get(apiUrl + "/sessions/logout");
    console.log(response);
  } catch(error) {
    throw new Error(error);
  }
}

export async function getProfileInfos() {
  try {
    const response = await axios.get(apiUrl + "/profiles" , {
      withCredentials: true
    });
    console.log(response)
    return response
  } catch(error) {
    throw new Error(error);
  }
}

export async function createPhysicianAccount() {
  var body = {
    "role":"physician",
    "gender": 1,
    "password":"abcdeABCDE1234?",
    "firstName":"John",
    "lastName": "Doe",
    "phone": "0000000000",
    "phoneEmergency": "0000000000",
    "mail": "example@gmail.com",
    "speciality": "Dummy speciality",
    "faculty": "Example",
    "adeli": "000000000",
    "finess": 1,
    "clinicat": true,
    "sector": 2,
    "adressPro": "Example",
    "adressConsult": "Example",
    "optam": true,
    "coOptam": false,
  }

  try {
    const response = await axios.post(apiUrl + "/sessions/register", body, {
      withCredentials: true
    })

    return response;
  } catch(error) {
    throw new Error(error);
  }
}

// Real function
export async function isAdmin(id) {
  return true;
}

export async function createAccount(adeli, email, password, gender, role, firstName, lastName, phone, phoneEmergency, speciality, faculty) {
  const loginData = {
    "adeli" : adeli,
    "mail": email,
    "password": password,
    "role": role,
    "gender": gender,
    "firstName" : firstName,
    "lastName" : lastName,
    "phone": phone,
    "phoneEmergency": phoneEmergency,
    "speciality": speciality,
    "faculty": faculty,
    "finess": 1,                     
    "clinicat": true,                
    "sector": 2,
    "adressPro": "Example",
    "adressConsult": "Example",
    "optam": true,
    "coOptam": false,
    "isValidated": 1
  }

  console.log(loginData);

    try {
      const response = await axios.post(apiUrl + "/sessions/register", loginData, {
          withCredentials: true
      });

      return response;    
    } catch(error) {
      console.log("error")
      throw new Error(error);
    }
}

export async function getAllPhysicians() {
  try {
      const response = await axios.get(apiUrl + "/profiles/physicians", {
          withCredentials: true
      });
      
      return response;
  } catch(error) {
      throw new Error(error);
  }
}