import { logOut } from "../services/users";
import { useNavigate } from 'react-router-dom';

export default function LogOutButton() {
    const navigate = useNavigate();
    
    
    const handleLogOut = async () => {
      await logOut();
      navigate("/");
    }

    return(
        <button style={{backgroundColor:"red"}} onClick={handleLogOut}>LOG OUT</button>
    )
}