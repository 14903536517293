import './PhysiciansList.css';
import PhysiciansRow from './PhysiciansRow';
import { useState } from 'react';
import { Button } from '@chakra-ui/react'
import { validUser } from '../../services/users';

export default function PhysiciansList({ physicians }) {
    const [selectedAdelis, setSelectedAdelis] = useState([]);

    const handleCheckboxChange = (adeli, checked) => {
        if (checked) {
            setSelectedAdelis(prevSelectedAdelis => [...prevSelectedAdelis, adeli]);
        } else {
            setSelectedAdelis(prevSelectedAdelis => prevSelectedAdelis.filter(item => item !== adeli));
        }
    };

    const handleValidateClick = () => {
        // console.log("Adeli sélectionnés :", selectedAdelis);
        selectedAdelis.forEach(async (adeli) => {
            try {
                var response = await validUser(adeli);
                console.log(response)
            } catch(error) {
                throw new Error(error);
            }
        });
    };

    return (
        <div className='container'>
            <h1>Liste des comptes practiciens</h1>
            <div className="list-container">
                {physicians.length === 0 ? (
                    <div className="row-container">Aucuns praticiens en base</div>
                ) : (
                    physicians.map((infos, index) => (
                        <div className='row-container' key={index}>
                            <PhysiciansRow infos={infos} onCheckboxChange={handleCheckboxChange} />
                        </div>
                    ))
                )}
            </div>
            <Button onClick={handleValidateClick}>Valider ces practiciens</Button>
        </div>
    );
}
