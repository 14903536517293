// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.row-element {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.row-text {
    display: flex;
    align-items: center;
    margin-right: auto; 
    width: 100%;
    font-weight: bold;
}

.row-text:hover {
    background-color: rgb(217, 217, 217);
    border-radius: 10px; 
    padding: 10px;
}

.button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

`, "",{"version":3,"sources":["webpack://./src/packs/List/Row.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,oCAAoC;IACpC,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;AAC7B","sourcesContent":[".row-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n\r\n.row-element {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: center;\r\n    width: 100%;\r\n}\r\n\r\n.row-text {\r\n    display: flex;\r\n    align-items: center;\r\n    margin-right: auto; \r\n    width: 100%;\r\n    font-weight: bold;\r\n}\r\n\r\n.row-text:hover {\r\n    background-color: rgb(217, 217, 217);\r\n    border-radius: 10px; \r\n    padding: 10px;\r\n}\r\n\r\n.button-container {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: flex-end;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
