

import './Row.css';
import { useState, useEffect } from 'react';
import { getAllTreatments } from '../../services/application';
import TreatmentRow from '../Row/TreatmentRow';

export default function TreatmentsList ({ treatments }) {

    
    return (
        <div className="row-container">
            {treatments.length == 0 ? (
                <div className="row-element">Aucuns traitements en base</div>
            ) : (
                treatments.map(infos => (
                    <TreatmentRow infos={infos} />
                ))
            )}
        </div>
    )
}