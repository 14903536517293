import axios from 'axios';

import apiUrl from "../config";

export async function createTreatment(codeTTT, creator) {
    const data = {
        "codeTTT": codeTTT,
        "creator": creator,
        "private": false,
        "readOnly": true
      }

      try {
        const response = await axios.post(apiUrl + "/treatments", data, {
            withCredentials: true
        });

        return response;
      } catch(error) {
        throw new Error(error);
      }
}

export async function getAllTreatments() {
    try {
        const response = await axios.get(apiUrl + "/treatments", {
            withCredentials: true
        });
        
        return response;
    } catch(error) {
        throw new Error(error);
    }
}

export async function deleteTreatment(id) {
    try {
        const reponse = await axios.delete(apiUrl + "/treatments/" + id, {
            withCredentials: true
        });

        return reponse;
    } catch(error) {
        throw new Error(error);
    }
}
