import { useState } from "react";
import LogOutButton from "../components/LogOutButton";
import Layout from "../layout/Layout";
import { createAccount, validUser } from "../services/users";
import { createPhysicianAccount } from "../services/users";
import { Button, Text, Select } from '@chakra-ui/react'


import MyInput from '../components/Input'

// "role":"physician",              OK 
// "gender": 1,                     OK
// "password":"abcdeABCDE1234?",    OK
// "firstName":"John",              OK
// "lastName": "Doe",               OK
// "phone": "0000000000",           OK
// "phoneEmergency": "0000000000",  OK
// "mail": "example@gmail.com",     OK
// "speciality": "Dummy speciality",OK
// "faculty": "Example",            OK
// "adeli": "000000000",            OK
// "finess": 1,                     
// "clinicat": true,                
// "sector": 2,
// "adressPro": "Example",
// "adressConsult": "Example",
// "optam": true,
// "coOptam": false,


function CreateAccountPage() {
  const [adeli, setAdeli] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState(1);
  const [role, setRole] = useState("physician");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneEmergency, setPhoneEmergency] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [faculty, setFaculty] = useState("");

  const [adeliToValidate, setAdeliToValidate] = useState("");

  const handleCreateAccount = async () => {
    try {
      var response = await createAccount(adeli, email, password, gender, role, firstName, lastName, phone, phoneEmergency, speciality, faculty);
      // await createPhysicianAccount();
      console.log(response);
    } catch(error) {
      throw new Error(error);
    }
  }

  const validateAmeli = async () => {
    try {
      var response = await validUser(adeliToValidate);
      console.log(response)
    } catch(error) {
      throw new Error(error);
    }
  }

  return (
    <>
        <div>To do : création de compte practicien/secretaire (2 menus différents)</div>
        <div className='admin-panel'>
        {/* <MyInput label="validate user" value={adeliToValidate} onChange={(e) => setAdeliToValidate(e.target.value)} />
        <Button colorScheme='blue' onClick={validateAmeli} >Valider l'ameli</Button> */}

        <Text fontSize='4xl' fontWeight='bold'>Practicien Créations</Text>

        <div className='home-input'>
          <MyInput label="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
          <MyInput label="Mot de passe" value={password} onChange={(e) => setPassword(e.target.value)} />
          <MyInput label="Adeli" value={adeli} onChange={(e) => setAdeli(e.target.value)} />
          <Select onChange={(e) => {
            setGender(e.target.value);
            console.log(typeof e.target.value);
        }
          } >
            <option value={1}>Homme</option>
            <option value={2}>Femme</option>
          </Select>

          <Select onChange={(e) => setRole(e.target.value)} >
            <option value='physician'>Practicien</option>
            <option value='secretary'>Secrétaire</option>
          </Select>
          <MyInput label="Prénom" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
          <MyInput label="Nom" value={lastName} onChange={(e) => setLastName(e.target.value)} />
          <MyInput label="Téléphone" value={phone} onChange={(e) => setPhone(e.target.value)} />
          <MyInput label="Téléphone D'urgence" value={phoneEmergency} onChange={(e) => setPhoneEmergency(e.target.value)} />
          <MyInput label="Spécialité" value={speciality} onChange={(e) => setSpeciality(e.target.value)} />
          <MyInput label="Faculté" value={faculty} onChange={(e) => setFaculty(e.target.value)} />


        </div>
          <Button colorScheme='blue' onClick={handleCreateAccount} >Créer le compte</Button>
      </div>

    </>

  );
}

export default CreateAccountPage;
