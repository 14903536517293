import { 
    IconButton
} from '@chakra-ui/react'

import {
    EditIcon,
    DeleteIcon
 } from '@chakra-ui/icons'

import './Row.css';

export default function PacksRow () {
    const handleClick = () => {

    }
    return (
        <>
            <div className="row-container">
                <div className="row-element">
                    <button className="row-text" onClick={handleClick}>Pack 1 | Pouvoir voir le code TTT de chaque pack</button>
                    <IconButton
                        isRound={true}
                        icon={<EditIcon color="black"/>}
                        colorScheme=''
                        // onClick={onClick}
                    />

                    <IconButton
                        isRound={true}
                        icon={<DeleteIcon color="red"/>}
                        colorScheme=''
                        // onClick={handleDelete}
                    />
                </div>

                <div className="row-element">
                    <button className="row-text" onClick={handleClick}>Pack 2</button>
                    <IconButton
                        isRound={true}
                        icon={<EditIcon color="black"/>}
                        colorScheme=''
                        // onClick={onClick}
                    />

                    <IconButton
                        isRound={true}
                        icon={<DeleteIcon color="red"/>}
                        colorScheme=''
                        // onClick={handleDelete}
                    />
                </div>
                <div className="row-element">
                    <button className="row-text" onClick={handleClick}>Pack 3</button>
                    <IconButton
                        isRound={true}
                        icon={<EditIcon color="black"/>}
                        colorScheme=''
                        // onClick={onClick}
                    />

                    <IconButton
                        isRound={true}
                        icon={<DeleteIcon color="red"/>}
                        colorScheme=''
                        // onClick={handleDelete}
                    />
                </div>
            </div>
        </>
    )
}