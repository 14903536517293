import { 
    IconButton
} from '@chakra-ui/react'

import {
    EditIcon,
    DeleteIcon
 } from '@chakra-ui/icons'
import { deleteTreatment } from '../../services/application';

export default function TreatmentRow({ infos }) {
    const { id, codeTTT } = infos;
    const handleClick = () => {

    }

    const handleDelete = async () => {
        try {
            await deleteTreatment(id);
            console.log("del ok")
        } catch(error) {
            throw new Error(error);
        }
    }
    return (
        <div key={infos.id} className="row-element">
                            
            <button className='row-text'  onClick={handleClick}>{codeTTT}</button>
            
            <div className='button-container'>
                <IconButton
                    isRound={true}
                    icon={<EditIcon color="black"/>}
                    colorScheme=''
                    // onClick={onClick}
                />

                <IconButton
                    isRound={true}
                    icon={<DeleteIcon color="red"/>}
                    colorScheme=''
                    onClick={handleDelete}
                />
            </div>

        </div>
    )
} 