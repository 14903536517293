import { useState, useEffect } from "react";
import LogOutButton from "../components/LogOutButton";
import Layout from "../layout/Layout";
import { createAccount, getAllPhysicians, validUser } from "../services/users";
import { createPhysicianAccount } from "../services/users";
import { Button, Text, Select } from '@chakra-ui/react'


import MyInput from '../components/Input'
import PhysiciansList from "./List/PhysiciansList";

function AdminPanel() {
  const [adeli, setAdeli] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState(1);
  const [role, setRole] = useState("physician");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneEmergency, setPhoneEmergency] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [faculty, setFaculty] = useState("");

  const [adeliToValidate, setAdeliToValidate] = useState("");

  const [physicians, setPhysicians] = useState([]);

  const handleCreateAccount = async () => {
    try {
      var response = await createAccount(adeli, email, password, gender, role, firstName, lastName, phone, phoneEmergency, speciality, faculty);
      // await createPhysicianAccount();
      console.log(response);
    } catch(error) {
      throw new Error(error);
    }
  }

  const validateAmeli = async () => {
    try {
      var response = await validUser(adeliToValidate);
      console.log(response)
    } catch(error) {
      throw new Error(error);
    }
  }

  const [updateTrigger, setUpdateTrigger] = useState(false);

  useEffect(() => {
      const fetchPhysicians = async () => {
          try {
              const response = await getAllPhysicians();
              console.log(response.data)
              setPhysicians(response.data);
          } catch (error) {
              console.error("Une erreur s'est produite lors de la récupération des traitements :", error);
          }
      };

      fetchPhysicians();
  }, [updateTrigger]);

  return (
    <>
    <LogOutButton/>
      <div>
        <PhysiciansList physicians={physicians}/>
      </div>

    </>

  );
}

export default AdminPanel;
