import { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Input
  } from '@chakra-ui/react'

import { useDisclosure } from '@chakra-ui/react';
import { createTreatment, getAllTreatments } from '../../services/application';
import { getProfileInfos } from '../../services/users';
import './CreateTreatment.css'

function CreateTreatment({ onTreatmentCreated }) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleClick = async () => {
        if(inputValue !== "") {
            try {
                var response = await getProfileInfos();
                var codeCreator = response.data.adeli;
                console.log("Profils info :")
                console.log(response);
                await createTreatment(inputValue, codeCreator );
                onTreatmentCreated();
                onClose();


                // test zone
                var AllTreatments = await getAllTreatments()
                console.log("Tout les traitements : ")
                console.log(AllTreatments);
            } catch(error) {
                throw new Error(error);
            }
        }
    }
    return (

        
        <>
            <Button onClick={onOpen}>Créer un traitement</Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Créer un traitement</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        <div className="modal-container">
                        <button onClick={getProfileInfos}>info </button>

                            <div>
                                Code TTT
                                <Input className="input-ttt" value={inputValue} onChange={handleInputChange}></Input>
                            </div>
                            <div>
                                TO DO : pouvoir ajouter des packs lors de la creation d'un traitement
                            </div>

                        </div>

                        {/* <div>
                            Nom du pack
                            <Input label='Nom du pack'></Input>
                        </div> */}
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={handleClick}>
                        Valider
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default CreateTreatment;
