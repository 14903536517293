import { Button, Text } from '@chakra-ui/react'
import MyInput from '../components/Input'
import QRCode from 'qrcode.react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  getAllTreatments,
  createTreatment
} from '../services/application';

import './Home.css'
import { createPhysicianaAccount, getProfileInfos, logAdmin, logOut, validUser } from '../services/users';


function Home() {
  const [data, setData] = useState('');

  const [index, setIndex] = useState(1);
  const [isScanning, setIsScanning] = useState(false);
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [dateNaissance, setDateNaissance] = useState('');
  const [genre, setGenre] = useState('');
  const [codeTTT, setCodeTTT] = useState('');
  const [cote, setCote] = useState('');
  const [datePrevisible, setDatePrevisible] = useState('');

  const [showQRCode, setShowQRCode] = useState(false);
  const [qrCodeData, setQRCodeData] = useState('');

  const navigate = useNavigate();

  async function handleRequest() {

    
    try {
      // await logOut();
      // var response = await logAdmin();
      // var response = await getProfileInfos()
      // console.log(response);
      console.log("here")
      var response = await getProfileInfos();
      console.log(response)
      // await createTreatment("testTTT", "creatortest");
      // var response = await getAllTreatments();
    } catch (error) {
      console.error('An error occurred:', error);
    }  
  }

  const handleClickGenerate = () => {
    // Générer le QRCode ici
    const data = nom + ',' + prenom + ',' + dateNaissance + ',' + genre + ',' + codeTTT + ',' + cote + ',' + datePrevisible;
    setShowQRCode(true);
    setQRCodeData(data);
  };

  const handleClickScan = () => {
    if (!isScanning) {
      setIsScanning(true);
      setNom('');
      setPrenom('');
      setDateNaissance('');
      setGenre('');
      setCodeTTT('');
      setCote('');
      setDatePrevisible('');
    } else {
      setIsScanning(false);
    }
  }

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (isScanning) {
        if (e.key !== ',') {
          // Mettre à jour les valeurs en fonction de la position actuelle de `index`
          switch (index) {
            case 1:
              setNom((nom) => nom + e.key);
              break;
            case 2:
              setPrenom((prenom) => prenom + e.key);
              break;
            case 3:
              setDateNaissance((date) => date + e.key);
              break;
            case 4:
              setGenre((genre) => genre + e.key);
              break;
            case 5:
              setCodeTTT((codeTTT) => codeTTT + e.key);
              break;
            case 6:
              setCote((cote) => cote + e.key);
              break;
            case 7:
              setDatePrevisible((date) => date + e.key);
              break;
            default:
              break;
          }
        } else {
          console.log(index);
          setIndex((prevIndex) => prevIndex + 1); // Incrémenter `index` lorsqu'une virgule est détectée
        }

        // 'Enter' est la touche pressée par le scanner à la fin
        if (e.key === 'Enter') {
          // Réinitialiser l'état de scan
          setIsScanning(false);
        }
      }
    };

    // Ajouter un écouteur d'événements pour la gestion des touches
    window.addEventListener('keypress', handleKeyPress);

    // Nettoyer l'écouteur lorsque le composant est démonté
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [isScanning, index]); // Écouter les changements d'état d'isScanning et index

  return (
    <>
      <div className='home'>
        <Text fontSize='4xl' fontWeight='bold'>Veuillez scanner un QR Code existant ou remplir les champs</Text>

        <div className='home-input'>
          <MyInput label="Nom" value={nom} onChange={(e) => setNom(e.target.value)} />
          <MyInput label="Prénom" value={prenom} onChange={(e) => setPrenom(e.target.value)} />
          <MyInput label="Date de naissance" value={dateNaissance} onChange={(e) => setDateNaissance(e.target.value)} />
          <MyInput label="Genre" value={genre} onChange={(e) => setGenre(e.target.value)} />
          <MyInput label="Code TTT" value={codeTTT} onChange={(e) => setCodeTTT(e.target.value)} />
          <MyInput label="Côté" value={cote} onChange={(e) => setCote(e.target.value)} />
          <MyInput label="Date prévisible" value={datePrevisible} onChange={(e) => setDatePrevisible(e.target.value)} />
        </div>
        <div>
          <Button colorScheme='blue' onClick={handleClickGenerate}>Générer parcours</Button>
          {isScanning ? (
            <Button colorScheme='red' onClick={handleClickScan}>STOP SCANNING</Button>
          ) : (
            <Button colorScheme='blue' onClick={handleClickScan}>Scanner QR Code</Button>
          )}
        </div>
        {showQRCode && (
          <div className='qr-code'>
            <QRCode value={qrCodeData} />
          </div>
        )}
        <Button colorScheme='pink' onClick={handleRequest}>test requete</Button>

      </div>
    </>
  )
}

export default Home;
