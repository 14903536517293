import React from 'react';
import { Input } from '@chakra-ui/react'

function MyInput({label, placeholder, onChange, value}) {
  return (
    <div>
      {label && <label>{label}</label>}
      <Input placeholder = {placeholder} borderColor='black' value={value} onChange={onChange}/>
    </div>
  );
}

export default MyInput;
