import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { ChakraProvider } from '@chakra-ui/react';

import Login from './login/Login';
import CreateAccountPage from "./login/CreateAccountPage";
import AdminPanel from "./admin/AdminPanel";

import './App.css';
import UserPanel from "./user/UserPanel";

function App() {
  // isuser => return userPage
  // isAdmin => return adminPage
  return (
    <ChakraProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/create" element={<CreateAccountPage />} />
                    
          <Route path="/admin/*" element={<AdminPanel />} />
          <Route path="/user/*" element={<UserPanel />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;


        {/* 

        <Route
            path="/home"
            element={
              <Layout>
                <Home />
              </Layout>
            }
        />

        <Route
            path="/add"
            element={
              <Layout>
                <div>
                  add a practicien here
                </div>
              </Layout>
            }
        />

        <Route
            path="/packs"
            element={
              <Layout>
                <Pack />
              </Layout>
            }
        /> */}