import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from '../layout/Layout';
import Home from '../home/Home';
import Pack from '../packs/Pack';

export default function UserPanel() {
    return(
        <Layout>
            <Routes>
                <Route
                    path="/home"
                    element={
                        <Home />
                    }
                />
                <Route
                    path="packs"
                    element={
                        <Pack />
                    }
                />
            </Routes>
        </Layout>
    )
}
