import { Checkbox } from '@chakra-ui/react'

export default function PhysiciansRow({ infos, onCheckboxChange }) {
    const handleCheckboxClick = (event) => {
        const checked = event.target.checked;
        onCheckboxChange(infos.adeli, checked);
    };

    return (
        <>
            <div className='row-element'>{infos.adeli}</div>
            <div className='row-element'>{infos.lastName}</div>
            <Checkbox onChange={handleCheckboxClick}></Checkbox>
        </>
    );
}
