// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
    display: flex;
    flex-direction: column;
    /* background-color: rgb(201, 137, 137); */
    align-items: center;
    justify-content: center;
}

.home-input {
    /* display:flex; */
    /* background-color: aquamarine; */
    /* flex-direction: row; */
    padding-top: 10px;
    padding-bottom: 25px;
    
}`, "",{"version":3,"sources":["webpack://./src/home/Home.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,0CAA0C;IAC1C,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,kCAAkC;IAClC,yBAAyB;IACzB,iBAAiB;IACjB,oBAAoB;;AAExB","sourcesContent":[".home {\r\n    display: flex;\r\n    flex-direction: column;\r\n    /* background-color: rgb(201, 137, 137); */\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.home-input {\r\n    /* display:flex; */\r\n    /* background-color: aquamarine; */\r\n    /* flex-direction: row; */\r\n    padding-top: 10px;\r\n    padding-bottom: 25px;\r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
