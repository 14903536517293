// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.categorie {
    /* background-color: red; */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    
    border-radius: 10px;
    padding: 10px; /* Ajoute un espace intérieur pour augmenter la zone de background-color */
}

.categorie:hover {
    background-color: #DCDEE1;
}

.categorie:hover > a {
    text-decoration: none; /* Retire le soulignage du texte lors du survol */
}`, "",{"version":3,"sources":["webpack://./src/layout/component/LayoutSidebar.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,4BAA4B;IAC5B,6BAA6B;IAC7B,+BAA+B;IAC/B,gCAAgC;;IAEhC,mBAAmB;IACnB,aAAa,EAAE,0EAA0E;AAC7F;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB,EAAE,iDAAiD;AAC5E","sourcesContent":[".categorie {\r\n    /* background-color: red; */\r\n    border-top-left-radius: 10px;\r\n    border-top-right-radius: 10px;\r\n    border-bottom-left-radius: 10px;\r\n    border-bottom-right-radius: 10px;\r\n    \r\n    border-radius: 10px;\r\n    padding: 10px; /* Ajoute un espace intérieur pour augmenter la zone de background-color */\r\n}\r\n\r\n.categorie:hover {\r\n    background-color: #DCDEE1;\r\n}\r\n\r\n.categorie:hover > a {\r\n    text-decoration: none; /* Retire le soulignage du texte lors du survol */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
